.table__container__workspaces {
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;
  }

  thead {
    tr th {
      text-align: left;
      font-size: 14px;
      font-weight: 500;
      padding: 0;
      height: 60px;
      color: #545454;

      button {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        cursor: pointer;
        transition: all 0.2s ease;
        background: #dfdfdf;

        &:hover {
          opacity: 0.8;
        }
      }

      &:first-of-type {
        padding: 0 16px;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        background: #dfdfdf;
        min-width: 22rem;
        width: 100%;
      }

      &:last-of-type {
        text-align: center;
        min-width: 10rem;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        background: #dfdfdf;
      }

      & + th {
        border-left: 1px solid #c8c8c8;
        min-width: 17rem;
      }
    }
  }

  tbody {
    tr td {
      text-align: left;
      font-size: 14px;
      padding: 0;
      height: 60px;
      padding: 0 16px;
      background: #ffffff;
      color: #363636;

      span {
        padding: 2px 8px;
        background: #ffc966;
        border-radius: 999px;
        white-space: nowrap;
      }

      &:first-of-type {
        font-weight: bold;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;

        div {
          width: fit-content;
          cursor: pointer;
          transition: all 0.2s ease;

          &:hover {
            opacity: 0.7;
          }
        }
      }

      &:last-of-type {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;

        div {
          display: flex;
          justify-content: center;
          gap: 16px;

          button {
            height: 32px;
            width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            background: transparent;
            border: 0;
            cursor: pointer;
            transition: color 0.2s ease;

            &:hover {
              color: #ed276a;
            }
          }
        }
      }
    }
  }

  .table-messages {
    display: block;
    width: fit-content;
    margin: 0 auto;
    cursor: pointer;
    font-size: inherit;
    color: inherit;
    font-weight: normal;

    .icon-messages {
      width: 27px;
      height: 27px;
      font-size: 27px;
      margin-right: 0;
      cursor: pointer;
      transition: color 0.2s ease;

      &:hover {
        color: #ed276a;
      }
    }
  }
}
