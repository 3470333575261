.background-page.rm {
  display: flex;
}

.container_rm {
  margin: 0 auto;
  padding: 40px;
  box-sizing: content-box;
  flex: 1;
  display: flex;
  flex-direction: column;

  header {
    padding: 0;
    margin: 0;
  }

  .input-junction .input {
    background: #ffffff;
  }
}

.campaign_details_rm {
  margin: 1rem 0;
  padding: 0;
  display: flex;
  align-items: center;
  list-style: none;

  > li {
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    & + li {
      padding-left: 16px;
      border-left: 1px solid #e2e2e2;
    }

    > svg {
      margin-right: 8px;
      color: #ed276a;
    }

    &:last-of-type {
      margin-left: auto;
      padding-right: 0;

      button {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ed276a;
        border: none;
        cursor: pointer;
      }
    }
  }
}

.status__campaign_details_rm {
  margin-left: 8px;
  padding: 2px 8px;
  background: #ffc966;
  border-radius: 999px;
  white-space: nowrap;
}

.tags__campaign_details_rm {
  margin-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabs_rm {
  margin-top: 1rem;
  margin-bottom: 0;
  display: flex;
  gap: 16px;
  list-style: none;
  padding: 0;
  border-bottom: 1px solid #b3b3b3;

  li {
    display: flex;

    &:last-of-type {
      margin-left: auto;
    }
  }

  button:only-child {
    padding: 0 8px;
    color: #363636;
    font-weight: 400;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    border: none;
    border-bottom: 4px solid transparent;
    cursor: pointer;
    font-size: 18px;
    background: transparent;

    &:hover {
      color: #ed276a;
    }

    &[data-active='true'] {
      border-color: #ed276a;
      color: #ed276a;
    }
  }

  div {
    margin-top: auto;
    margin-bottom: 4px;
    width: 400px;
  }

  div + button {
    margin: auto 0 4px 12px;
    padding: 0;
    min-width: 40px;
  }
}

.board_rm {
  --board-gap: 1.25rem;
  margin-top: 1rem;
  display: flex;
  gap: var(--board-gap);
  flex: 1;
  overflow-x: auto;
  
  .board_rm_loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.button__board_rm {
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ed276a;
  color: #ffffff;
  transition: 0.2s ease-in all;
  border-radius: 12px;
  border: none;
  min-width: 280px;
  gap: 6px;
  font-weight: bold;
  line-height: 24px;

  &:hover {
    opacity: 0.6;
  }

  &[data-size='icon'] {
    height: 40px;
    min-width: 40px;

    span {
      display: none;
    }
  }
}

.columns_rm {
  display: flex;
  gap: var(--board-gap);
  flex: 1;
}

.column_rm {
  background: #e2e2e2;
  display: flex;
  flex-direction: column;
  width: 280px;
  border-radius: 8px;
  --header-color: #ed276a;

  ::-webkit-scrollbar {
    width: 0;
  }

  ::-webkit-scrollbar-track {
    background: #e2e2e2;
  }

  > header {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    b {
      color: #333333;
    }

    button {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }

  > footer {
    margin-top: auto;
    padding: 8px;

    button {
      width: 100%;
      background: #ffffff !important;
      border: 1px solid #ed276a;
      color: #ed276a !important;

      &:hover {
        border-color: #ed276a;
      }
    }
  }
}

.cards_rm {
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: calc(100vh - (80px + 35px + 72px + 77px + 40px + 56px));
  overflow-y: auto;
  flex: 1;
  transition: background-color 0.2s;
}

.card_rm {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  width: 100%;
  border-radius: 6px;
  border-top: 6px solid var(--header-color);
  padding: 8px 12px 8px;
  gap: 12px;

  > ul {
    padding: 0;
    margin: 0;
    list-style: none;

    display: flex;
    gap: 12px;

    li {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      span {
        font-size: 10px;
      }

      b {
        font-size: 12px;
        display: flex;
        align-items: baseline;
        gap: 4px;
      }
    }
  }
}

.header__card_rm {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 8px;

  .avatar-network .network-logo {
    height: 16px;
    width: 16px;
    right: 0;
    bottom: 0;
  }

  > div:last-of-type {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      b {
        font-size: 12px;
        color: #484848;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      button {
        height: 20px;
        width: 20px;
        padding: 0;
        display: flex;
        align-items: center;
        color: #7b7b7b;
        justify-content: center;
        border: none;
        transition: 0.2s ease-in-out;
        cursor: pointer;
        background: transparent;

        &:hover {
          color: #363636;
        }
      }
    }

    span {
      font-size: 12px;
      display: block;
      color: #484848;
    }
  }
}

.footer__card_rm {
  padding-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-top: 1px solid #c6c6c6;

  p + svg {
    color: #ed276a;
  }

  p {
    color: #484848;
    margin: 0;
    font-weight: 500;
  }

  button {
    padding: 0;
    border: none;
    background: transparent;
    color: #ed276a;
    margin-left: auto;
    text-decoration: underline;
    cursor: pointer;
    font-size: 12px;
  }
}

.report_root {
  width: 100%;
  box-sizing: border-box;
  padding-top: 20px;
}

.td-inlfuencer-connect {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 96px;

  p {
    cursor: pointer;
    margin-right: 7px;
    margin-bottom: 0px;
  }
}

.influencer-post-avatar {
  width: 100%;
  display: flex;
  align-items: center;

  span {
    margin-right: 5px;
  }
}

.influencer-post-content {
  position: relative;
  display: inline-block;

  .img {
    display: block;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
}
.content-thumb-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.content-thumb {
  background-repeat: no-repeat;
  background-position: center center;
  width: 100px;
  height: 100px;
  background-color: #f2f2f2;
  background-size: 100px auto;
  cursor: pointer;
  border-radius: 9px;
}

.subtitle-part {
  font-weight: 800;
  font-size: 16px;
}

.empty-data {
  width: 100%;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.div-flex {
  .avatar {
    margin-right: 0px !important;
  }
  .name-username {
    margin-left: 24px !important;
  }
}


.interaction-values {
  display: flex;
  gap: 24px 15px;

  .interaction {
    background-color: white;
      width: 100%;
      padding: 18px;
      display: flex;
      border-radius: 8px;
      margin-bottom: 24px;
      flex-direction: column;
      align-items: flex-start;
      background-color: getColor(background, lightest);

      &:last-child {
          border-right: none;
      }

      &.with-icons {
          .icon {
              font-size: 40px;
              color: getColor(primary);
          }

          .interaction-value {
              font-size: 40px;
              cursor: default;
              margin: 20px 0 8px;
          }
      }

      .interaction-value {
          font-size: 20px;
          margin: 0 0 8px;
          font-weight: 700;
      }

      .interaction-name {
          display: flex;
          font-size: 16px;

          .tooltip {
              margin-left: 8px;
          }
      }
  }
}

.post-type {
  position: absolute;
  top: 10px;
  z-index: 999;
  background-color: white;
  border-radius: 10px;
  width: 45px;
  display: flex;
  justify-content: center;
}

.add-in-campaign-toggle {
  position: absolute;
  z-index: 999;
  top: 10px;
  right: 10px;
}

.post-heigher {
  height: 360px !important;
}

.post-filter {
  // width: 100%;
  padding: 10px;
  display: flex;
  justify-content: end;
}